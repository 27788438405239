@import "../SCSS/config";

.footer {
  .socials-container {
    padding: 2vh;

    .socials-items {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 25%;
    }

    a {
      border: 0.5px solid $SemiBlack;
      border-radius: 100%;
      background-color: $white;
    }

    i {
      color: $SemiBlack;
    }
  }

  .main-footer-container {
    margin-top: 5vh;
    padding: 6vh;
    background-color: $GhostColor;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 10vh;
      }
    }

    .footer-links-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      h2 {
        font-size: 18px;
        font-weight: 700;
        color: $HeadingColor;
        padding: 5px;
      }
      ul {
        text-align: center;
        li {
          a {
            font-size: var(--fs-paragraph);
            color: $ParagraphColor;
          }
        }
      }
    }
  }

  .copyright-container {
    background-color: $HeadingColor;
    font-size: var(--fs-paragraph);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3vh;

    p {
      color: $white;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .footer {
    .main-footer-container {
      .logo-container {
        margin-bottom: 3vh;
        img {
          width: 7vh;
        }
      }

      .footer-links-container {
        h2 {
          font-size: 16px;
        }
      }
    }
  }
}
