@import "../SCSS/config";

.projects-items {
  padding: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 50vh;
    height: 40vh;
    border-radius: 6px;
    background-color: transparent;
  }
  h3 {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
    background-color: transparent;
    font-size: var(--fs-heading);
  }
  p {
    font-size: var(--fs-paragraph);
    color: $ParagraphColor;
    padding: 0;
  }
  span {
    font-size: var(--fs-paragraph);
  }
}

@media only screen and (max-width: 1000px) {
  .projects-container {
    margin: 0vh 0vh 4vh 0vh;
    .projects-items {
      padding: 5vh 2vh 0vh 2vh;
      img {
        width: 80%;
        height: 35vh;
      }
    }
  }
}
