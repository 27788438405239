@import "../SCSS/config";

.about-container {
  padding: 0vh 10vh 10vh 10vh;
  .profile-container {
    margin-bottom: 8vh;
    h1 {
      font-size: var(--fs-mainheading);
      font-weight: 700;
      color: $HeadingColor;
    }

    p {
      padding: 5px;
      color: $ParagraphColor;
      font-size: var(--fs-paragraph);
      text-align: justify;
    }
  }

  .product-and-services-container {
    h1 {
      font-size: var(--fs-mainheading);
      font-weight: 700;
      color: $HeadingColor;
    }

    span {
      font-family: $Roboto;
      font-size: var(--fs-paragraph);
      color: $SemiBlack;
      font-weight: 600;
      padding: 5px;
    }

    p {
      padding: 5px;
      color: $ParagraphColor;
      font-size: var(--fs-paragraph);
      text-align: justify;
    }

    ul {
      padding: 20px;
      list-style: inside;
      li {
        font-size: var(--fs-paragraph);
        color: $ParagraphColor;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .about-container {
    padding: 0vh 5vh;

    .profile-container {
      margin-bottom: 4vh;
    }
  }
}
